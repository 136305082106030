#body_login{
    background-image: url(../Imagenes/PanelWebLoginJpg.jpg); 
    background-size: cover;
    background-repeat: no-repeat;
    margin: 10;
    height: 100vh;
  
    overflow-x: hidden;
  }
  

#footer {
   
    left: 0;
    bottom: 0;
    width: 100%;
    color: rgb(0, 0, 0);
    text-align: center;
}

#footer_404 {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: rgb(0, 0, 0);
    text-align: center;
}


#card-login{
    border-radius: 10%;
    height: 100vh;  

}

#img_logo{
  margin-top: 20%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#btn_registrar{
    background-color: #192A70 ;
    border-color: none;
    width: 70%;
    border-radius: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#btn_registrar:hover{
    background-color: #020f41 ;
    border-color: none;
    width: 70%;
    border-radius: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#text-registrarte{
    color: #757575;
    font-weight: bold;
}

#msj_registro{
    color: #0BA3EC;
    font-size: small;
}

#copyright{
    color: #f1f2f3;
}

#body_login
{
     overflow-x:hidden;
     overflow-y:hidden;
}

#checkpass{
  color: #0976a8;
  text-decoration-line: underline;
}

#checkpass:hover{
    color: #192A70;
    text-decoration-line: underline;
  }