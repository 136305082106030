.aws-btn {

    --slider-height-percentage: 60%;
    --slider-transition-duration: 770ms;
    --organic-arrow-thickness: 13px;
    --organic-arrow-border-radius: 5px;
    --organic-arrow-height: 51px;
    --organic-arrow-color: #50b6d9;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #5ed6ff;
    --control-bullet-active-color: #50b6d9;
    --loader-bar-color: #6447b3;
    --loader-bar-height: 6px;
    
    }