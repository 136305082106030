#body_registrar{
    background-image: url(../Imagenes/FondoR.png); 
    background-size: cover;
    background-repeat: no-repeat;
    margin: 10;
    height: 100vh;
  
    overflow-x: hidden;
  }
  

#footer {
   
    left: 0;
    bottom: 0;
    width: 100%;
    color: rgb(0, 0, 0);
    text-align: center;
}

#footer_404 {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: rgb(0, 0, 0);
    text-align: center;
}


#card-registro{
    border-radius: 10%;
}

#btn_registrar{
    background-color: #192A70 ;
    border-color: none;
    width: 70%;
    border-radius: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#btn_registrar:hover{
    background-color: #020f41 ;
    border-color: none;
    width: 70%;
    border-radius: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#btn_eliminar_cuenta{
  background-color: #c04242 ;
  border-color: none;
  width: 70%;
  border-radius: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#btn_eliminar_cuenta:hover{
  background-color: #c71212 ;
  border-color: none;
  width: 70%;
  border-radius: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#text-registrarte{
    color: #192A70;
    font-weight: bold;
}

#msj_registro{
    color: #0BA3EC;
    font-size: small;
}

#msj_importante{
  color: #e92f3e;
  font-size: small;
}

#copyright{
    color: #f1f2f3;
}

.login-box {
  overflow:scroll;
 
}

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

.login-box::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  
  .login-box::-webkit-scrollbar:vertical {
    width:10px;
  }
  
  .login-box::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
    display: none;
  } 
  
  .login-box::-webkit-scrollbar:horizontal {
    height: 10px;
    display: none;
  }
  
  .card::-webkit-scrollbar-thumb {
    background-color: #AD1457;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
  
  .card::-webkit-scrollbar-track {
    border-radius: 10px;  
  }

